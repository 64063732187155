import {
  BoardCommonControllerService,
  BoardVo,
} from '@/__generated__/CommonApi';
import { FoBannerControllerService } from '@/__generated__/FrontApi';
import Event1 from '@/assets/img/tmp/donors/event_img1@2x.png';
import EventM1 from '@/assets/img/tmp/donors/event_img1M@2x.png';
import Event2 from '@/assets/img/tmp/donors/event_img2@2x.png';
import EventM2 from '@/assets/img/tmp/donors/event_img2M@2x.png';
import Event3 from '@/assets/img/tmp/donors/event_img3@2x.png';
import EventM3 from '@/assets/img/tmp/donors/event_img3M@2x.png';
import Obj1 from '@/assets/img/tmp/donors/event_obj1@2x.png';
import Obj2 from '@/assets/img/tmp/donors/event_obj2@2x.png';
import AdminBanner from '@/components/AdminBanner';
import { CampaignData } from '@/components/Card/CampaignCard';
import RelativeBoardCard from '@/components/Card/RelativeBoardCard';
import ContactUs from '@/components/ContactUs';
import Container from '@/components/Container';
import Image from '@/components/Image';
import LinkSafe from '@/components/LinkSafe';
import PageComponent from '@/components/PageComponent';
import Section, { SectionNews } from '@/components/Section';
import SectionHeader from '@/components/SectionHeader';
import { Tit } from '@/components/Titles';
import { breakpoint } from '@/helpers/BreakpointHelper';
import LayoutWithTitle from '@/layouts/LayoutWithTitle';
import { PageProps } from 'gatsby';
import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import 'swiper/components/navigation/navigation.min.css';
import 'swiper/components/pagination/pagination.min.css';
import 'swiper/components/scrollbar/scrollbar.min.css';

const contactData = [
  {
    id: 1,
    tit: `문의`,
    costumer: `후원자관계팀`,
    tel: `02-737-1004`,
    email: `donor@unicef.or.kr`,
  },
];

const EventArticel = styled.div`
  dl {
    display: flex;
    align-items: flex-start;
    margin: 0 -32px;
    margin-top: 32px;

    dt {
      width: 55%;
      padding: 0 32px;

      .img-wrap {
        position: relative;

        &:after {
          content: '';
          display: block;
          position: absolute;
          background-position: center;
          background-repeat: no-repeat;
          background-size: cover;
          z-index: -1;
        }
      }
    }

    dd {
      width: 45%;
      padding: 0 32px;
      padding-top: 40px;

      .dec {
        line-height: 1.7;
      }
      .blit-list {
        margin-top: 32px;
      }
    }
  }

  &.obj1,
  &.obj3 {
    .img-wrap {
      &:after {
        width: 584px;
        height: 487px;
        top: -98px;
        left: 265px;
        background-image: url(${Obj1});
      }
    }
  }
  &.obj2 {
    .img-wrap {
      &:after {
        width: 383px;
        height: 381px;
        top: 275px;
        left: -182px;
        background-image: url(${Obj2});
      }
    }
  }
  &.obj3 {
    .img-wrap {
      &:after {
        top: 205px;
        left: 260px;
      }
    }
  }

  .bn-item {
    display: block;
    margin-top: 32px;
  }

  ${breakpoint(1160)} {
    &.obj1,
    &.obj3 {
      .img-wrap {
        &:after {
          width: 50.34vw;
          height: 41.98vw;
          top: -8.45vw;
          left: 22.84vw;
        }
      }
    }
    &.obj2 {
      .img-wrap {
        &:after {
          width: 33.02vw;
          height: 33.02vw;
          top: 23.71vw;
          left: -15.69vw;
        }
      }
    }
    &.obj3 {
      .img-wrap {
        &:after {
          top: 17.67vw;
          left: 22.41vw;
        }
      }
    }
  }
  ${breakpoint(`mobile`)} {
    dl {
      flex-wrap: wrap;
      dt,
      dd {
        width: 100%;
      }

      dd {
        padding-top: 24px;

        .dec {
          line-height: 2;
        }
        .blit-list {
          margin-top: 24px;
        }
      }
    }
    .bn-item {
      margin-top: 48px;
    }

    &.obj1,
    &.obj3 {
      .img-wrap {
        &:after {
          width: 90.31vw;
          height: 75.31vw;
          top: -18.13vw;
          left: 53.75vw;
        }
      }
    }
    &.obj2 {
      .img-wrap {
        &:after {
          width: 54.69vw;
          height: 54.69vw;
          top: 43.13vw;
          left: -25vw;
        }
      }
    }
    &.obj3 {
      .img-wrap {
        &:after {
          top: 42.19vw;
          left: 36.56vw;
        }
      }
    }
  }
`;

const SectionTop = styled(Section)``;

const SectionEvnet = styled(Section)`
  .contactus-container {
    margin-top: 96px;
  }
`;

const Event: React.FC<PageProps> = ({ location }) => {
  const [thumbs, setThumbs] = useState<CampaignData[]>([]);

  const loadThumbs = useCallback(async () => {
    try {
      const { data } = await BoardCommonControllerService.foListUsingGet({
        boardCategoryCode: ['280', '290', '300'],
        pagePerCount: 8,
        category: ['A030'],
      });
      const articles = data as any;
      setThumbs(
        articles
          .map((article: BoardVo) => ({
            id: article.boardIndexNumber,
            title: article.subject,
            pcImage: article.imgAttGrpNoTn,
            mobileImage: article.imgAttGrpNoTn,
            link: `/what-we-do/news/${article.boardIndexNumber}`,
            date: article.firstRegisterDate,
            boardCategoryCode: article.boardCategoryCode,
          }))
          .sort((a, b) => new Date(b.date) - new Date(a.date) || b.id - a.id),
      );
    } catch (e) {
      console.error(e);
    }
  }, []);

  const [banner, setBanner] = useState([]);
  const loadBanner = useCallback(() => {
    FoBannerControllerService.selectBannerListUsingGet({
      kind: `0002`,
    })
      .then(({ data }) => {
        setBanner({ ...data });
      })
      .catch((e) => {
        console.error(e);
      });
  }, []);

  useEffect(() => {
    loadThumbs();
    loadBanner();
  }, [loadBanner, loadThumbs]);

  return (
    <LayoutWithTitle
      location={location}
      title="행사·이벤트"
      description="for every child, events"
    >
      <SectionTop className="by-sub-main-layout">
        <Container>
          <SectionHeader className="with-desc">
            <h2>
              <Tit size="s1-5" color="sky" weight="normal">
                <PageComponent id="title1">
                  후원자님들과 더 많은 순간들을 <br />
                  함께하고 싶습니다
                </PageComponent>
              </Tit>
            </h2>
            <p className="header-dec">
              <PageComponent id="content1">
                언제나 후원자님께 감사하는 마음을 전하고자 다양한 초청행사를
                진행하고, 유니세프의 기부와 나눔을 되새기는 특별한 시간을 만들고
                있습니다.
              </PageComponent>
            </p>
          </SectionHeader>

          <EventArticel className="obj1">
            <Tit size="s2">후원자 페스티벌(유자페스티벌)</Tit>
            <dl>
              <dt>
                <div className="img-wrap">
                  <Image pcSrc={Event1} mobileSrc={EventM1} />
                </div>
              </dt>
              <dd>
                <p className="dec">
                  유자페스티벌은 '유니세프 후원자 페스티벌'의 줄임말로, 후원자가
                  만들고 즐기는 페스티벌입니다. <br />
                  후원자님이 행사 기획, 부스 운영, 이벤트와 행사 진행 등
                  페스티벌 전 과정을 직접 운영하고 즐길 수 있는 기부 축제입니다.
                </p>
                <ul className="blit-list">
                  <li>행사 시기 : 격년</li>
                  <li>참가 대상 : 유니세프 정기후원자</li>
                </ul>
              </dd>
            </dl>
            <AdminBanner idx="0" kind="0002" />
          </EventArticel>
        </Container>
      </SectionTop>

      <SectionEvnet className="by-sub-main-layout">
        <Container>
          <EventArticel className="obj2">
            <Tit size="s2">다양한 문화행사</Tit>
            <dl>
              <dt>
                <div className="img-wrap">
                  <Image pcSrc={Event2} mobileSrc={EventM2} />
                </div>
              </dt>
              <dd>
                <p className="dec">
                  유니세프한국위원회는 언제나 후원자님에게 감사하는 마음으로
                  다양한 문화행사를 진행합니다. <br />
                  기부에 동참해 주시는 후원자님들과 더 많은 순간들을 함께하고
                  감사의 마음을 전하고자 합니다. 음악 및 발레 공연, 영화 시사회
                  등 다채로운 행사를 준비하고 있습니다.
                </p>
                <ul className="blit-list">
                  <li>모집 시기 : 수시</li>
                  <li>참가 대상 : 유니세프 정기후원자</li>
                </ul>
              </dd>
            </dl>
            <AdminBanner idx="1" kind="0001" />
          </EventArticel>
        </Container>
      </SectionEvnet>

      <SectionEvnet className="by-sub-main-layout">
        <Container>
          <EventArticel className="obj3">
            <Tit size="s2">참여 이벤트</Tit>
            <dl>
              <dt>
                <div className="img-wrap">
                  <Image pcSrc={Event3} mobileSrc={EventM3} />
                </div>
              </dt>
              <dd>
                <p className="dec">
                  유니세프한국위원회는 다양한 방법으로 후원자들과의 만남을
                  이어가고 있습니다. <br />
                  새해 다짐 챌린지 캠페인, 아동 권리 지지 참여 캠페인을 비롯한
                  다양한 캠페인과 이벤트를 마련하여 후원자님들의 어린이를
                  생각하는 마음과 목소리를 나누고자 합니다.
                </p>
                <ul className="blit-list">
                  <li>모집 시기 : 수시</li>
                  <li>참가 대상 : 유니세프 정기후원자</li>
                </ul>
              </dd>
            </dl>
            <AdminBanner idx="2" kind="0001" />
          </EventArticel>

          <div className="contactus-container">
            <ContactUs itemData={contactData} className="ContactUs" />
          </div>
        </Container>
      </SectionEvnet>

      <SectionNews className="by-sub-main-layout">
        <Container>
          <SectionHeader className="with-desc">
            <div className="flex-middle">
              <h2>
                <Tit size="s1">최근 소식</Tit>
              </h2>
              <LinkSafe to="/what-we-do/news?category=A030" className="more">
                <span className="txt">더 보기</span>
              </LinkSafe>
            </div>
          </SectionHeader>
          {thumbs.length > 0 && (
            <RelativeBoardCard
              newslist={thumbs}
              isDate={false}
              isSwiper
              overflowHidden
            />
          )}
        </Container>
      </SectionNews>
    </LayoutWithTitle>
  );
};

export default Event;
